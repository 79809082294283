import React from 'react'
import {graphql} from 'gatsby';
import Layout from '../components/layout/index.amp';
import loadable from "@loadable/component";
import {Title} from "../components/SEO";

const ArticlePreview = loadable(() => import("./articlePreview.amp"));

const SubCategoryPage = ({data, pageContext, location}) => {
  const getArticles = () => {
    const pillarArticle = (data.pillarArticles.nodes || [])[0];
    const allArticles = data.allDatoCmsArticle.nodes || [];
    let articles = [];
    if (pillarArticle) {
      articles.push(pillarArticle);
      articles = articles.concat(allArticles.filter(article => article.id !== pillarArticle.id))
    } else {
      articles = articles.concat(allArticles);
    }
    return articles;
  }
    return (
        <Layout path={location.pathname}>
            <Title title={pageContext.title}/>
            <div className="wrapper">
                <div className={"w-full"}>
                    <div className="text-denim uppercase font-bold mb-4 pb-2 border-b-default border-gray-400 text-lg">{pageContext.title}</div>
                    {getArticles().map((item) => {
                        return (
                            <ArticlePreview data={item}
                                            className={"border-b-default border-denim mb-4 pb-4"}
                                            imageClass="mb-0 w-1/3 float-right ml-2 mt-8 max-w-320"
                                            headingClass="font-montserrat font-bold leading-snug mb-1 md:mb-4 text-lg"
                                            textClass={"line-clamp l-c-3 mb-0"}
                                            imageFromUrl={true}
                                            imageMeta={{w: 370}}
                                            noFlag={true}/>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ArticlesSubCategoryWiseAMP($slug: String) {
      allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "at"}}}}, sort: {order: DESC, fields: publishedAt}) {
          nodes {
              ...ArticleFieldsPlugin
          }
          totalCount
      }
      pillarArticles: allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "at"}}}, isCategoryPinned: {eq: true}}, sort: {order: DESC, fields: publishedAt}, limit: 1) {
          nodes {
              ...ArticleFieldsPlugin
          }
      }
  }
`
export default SubCategoryPage
